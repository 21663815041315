import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";
import React from "react";
import { Container, Text } from "./join-team-elements";

const useStyles = makeStyles({
  paragraph: {
    margin: "auto",
    maxWidth: "400px",
  },
  link: {
    textDecoration: "none",
  },
});

const JoinTeam = () => {
  const classes = useStyles();

  return (
    <>
      <Container>
        <Text>
          <Typography variant="h2" component="h2">
            Join the Brother Bear team!
          </Typography>
        </Text>

        <Text>
          <Typography className={classes.paragraph} variant="subtitle1" component="p">
            Looking to take the next step? We have the perfect team to make your move seamless.
            Click below to contact us.
          </Typography>
        </Text>
        <Link to="/contact-us" className={classes.link}>
          <Button size="large" color="primary" variant="contained">
            Plan Your Move
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default JoinTeam;
