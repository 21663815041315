import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";

import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutUs from "../components/about-us/about-us";
import ContentContainer from "../components/content-container/content-container";
import Values from "../components/values/values";
import JoinTeam from "../components/join-team/join-team";
import theme from "../theme";

const AboutUsPage = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <SEO title="About Us" />
      <AboutUs />
      <ContentContainer isCentered>
        <Values />
        <JoinTeam />
      </ContentContainer>
    </Layout>
  </ThemeProvider>
);

export default AboutUsPage;
