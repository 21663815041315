import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Card = styled.div`
  position: relative;
  height: 100%;
`;

export const Text = styled.div`
  position: absolute;
  top: 10%;
  left: 20%;
  color: white;
  width: 270px;

  @media screen and (max-width: 1250px) {
    left: 15%;
  }

  @media screen and (max-width: 900px) {
    left: 25%;
    top: 20%;
  }

  @media screen and (max-width: 730px) {
    width: 200px;
    top: 15%;
  }

  @media screen and (max-width: 600px) {
    width: 200px;
    top: 10%;
  }

  @media screen and (max-width: 500px) {
    top: 5%;
    left: 5%;
  }
`;
