import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 24px 16px;

  @media screen and (max-width: 500px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const Description = styled.div`
  margin: auto;
  width: 450px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  color: #cacaca;
  font-size: 58px;
`;

export const RowBreak = styled.hr`
  width: 100%;
  border: none;

  @media screen and (max-width: 750px) {
    display: none;
    width: 0%;
  }
`;
