import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import MenuBookOutlinedIcon from "@material-ui/icons/MenuBookOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";
import Typography from "@material-ui/core/Typography";
import InformationIcon from "../InformationIcon/information-icon";
import GradientCard from "../gradient-card/gradient-card";
import Border from "../border/border";
import {
  AboutUsContainer,
  TextContainer,
  JumbotronTextContainer,
  Section,
  Image,
  ImageGrid,
  ImageGridItem,
  GridContainer,
} from "./about-us-elements";
import image1 from "../../images/movingContainerWithCustomer.png";
import image2 from "../../images/ryanAndMatt.png";
import image3 from "../../images/dolly.png";
import image4 from "../../images/movingItem.png";
import image5 from "../../images/boxes.png";

const useStyles = makeStyles({
  border: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "48px",
  },
  mainText: {
    padding: "24px 0",
  },
});

const AboutUs = () => {
  const classes = useStyles();

  return (
    <>
      <JumbotronTextContainer>
        <div className={classes.border}>
          <Border />
        </div>
        <Typography variant="h1" component="h1">
          Our Story Begins With You
        </Typography>
        <Typography variant="subtitle1" component="p">
          Our team of talented, customer-driven workers craft the best moving experience for our
          clients.
        </Typography>
      </JumbotronTextContainer>

      <ImageGrid>
        <ImageGridItem item={1}>
          <Image src={image2} />
        </ImageGridItem>
        <ImageGridItem item={2}>
          <GradientCard />
        </ImageGridItem>
        <ImageGridItem item={3}>
          <Image src={image1} />
        </ImageGridItem>
        <ImageGridItem item={4}>
          <Image src={image5} hideWhenMobile />
        </ImageGridItem>
        <ImageGridItem item={5}>
          <Image src={image3} />
        </ImageGridItem>
        <ImageGridItem item={6}>
          <Image src={image4} hideWhenMobile />
        </ImageGridItem>
      </ImageGrid>

      <AboutUsContainer>
        <Section>
          <TextContainer>
            <Typography variant="h3" component="h3" className={classes.mainText}>
              A moving experience like never before.
            </Typography>
            <Typography variant="subtitle1" component="p" color="textSecondary">
              We are a professional, local moving company that aims to serve our community. You will
              communicate directly with supervisors and workers who have been in the moving business
              for 20+ years. We offer the quality that you deserve.
            </Typography>
          </TextContainer>

          <GridContainer>
            <InformationIcon
              icon={<WbIncandescentOutlinedIcon fontSize="inherit" />}
              title="Rapid Growth"
            >
              Founded in 2016. We are a fast-growing, young company because our customers love our
              service.
            </InformationIcon>

            <InformationIcon
              icon={<FlashOnOutlinedIcon fontSize="inherit" />}
              title="Done In No Time"
            >
              Smart planning, years of experience and determination will lead you to asmooth moving
              process.
            </InformationIcon>

            <InformationIcon
              icon={<SupervisorAccountOutlinedIcon fontSize="inherit" />}
              title="Team Experience"
            >
              Our CEO, Ryan Carter, makes our team a family. Strong bonds make us work more
              efficiently.
            </InformationIcon>

            <InformationIcon
              icon={<ContactMailOutlinedIcon fontSize="inherit" />}
              title="Easy To Contact"
            >
              Available 24/7 at your convenience. Our website makes getting a quote easier than
              ever.
            </InformationIcon>

            <InformationIcon
              icon={<HomeWorkOutlinedIcon fontSize="inherit" />}
              title="Your Community"
            >
              Don't trust just anybody, trust local. We are your neighbors, we treat your items with
              care.
            </InformationIcon>

            <InformationIcon
              icon={<MenuBookOutlinedIcon fontSize="inherit" />}
              title="Coordination & Collaboration"
            >
              Your move gets staged by our best supervisors: we always come prepared.
            </InformationIcon>
          </GridContainer>
        </Section>
      </AboutUsContainer>
    </>
  );
};

export default AboutUs;
