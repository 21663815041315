import styled from "styled-components";

const getGridItemColumn = (item, isMobile) => {
  switch (item) {
    case 1:
      return isMobile ? "1 / 9" : "1 / 8";
    case 2:
      return isMobile ? "9 / 18" : "8 / 14";
    case 3:
      return isMobile ? "1 / 9" : "8 / 14";
    case 4:
      return isMobile ? "none" : "14 /16";
    case 5:
      return isMobile ? "9 / 18" : "1 / 6";
    case 6:
      return isMobile ? "none" : "6 / 16";
    default:
      return "none";
  }
};

const getGridItemRow = (item, isMobile) => {
  switch (item) {
    case 1:
      return isMobile ? "1 / 8" : "2 / 8";
    case 2:
      return isMobile ? "1 / 5" : "1 / 4";
    case 3:
      return isMobile ? "8 / 15" : "4 / 8";
    case 4:
      return isMobile ? "none" : "2 / 8";
    case 5:
      return isMobile ? "5 / 15" : "8 / 14";
    case 6:
      return isMobile ? "none" : "8 / 13";
    default:
      return "none";
  }
};

export const JumbotronTextContainer = styled.div`
  width: 450px;
  margin: auto;
  padding: 24px 0 40px 0;
  text-align: center;

  @media screen and (max-width: 500px) {
    width: 350px;
  }
`;

export const AboutUsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media screen and (max-width: 960px) {
    margin-top: 16px;
  }
`;

export const Card = styled.div`
  text-align: center;
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
  align-items: center;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin: 40px 0;
  width: 700px;

  @media screen and (max-width: 900px) {
    width: 90vw;
    margin: 24px 0;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 900px) {
    display: ${({ hideWhenMobile }) => (hideWhenMobile ? "none" : "block")};
  }
`;

export const ImageGrid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(15, 3fr);
  grid-template-rows: repeat(13, 5fr);
  width: 1200px;
  height: 900px;
  margin: auto;

  @media screen and (max-width: 1250px) {
    width: 900px;
  }

  @media screen and (max-width: 1000px) {
    width: 100vw;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const ImageGridItem = styled.div`
  grid-column: ${({ item }) => getGridItemColumn(item, false)};
  grid-row: ${({ item }) => getGridItemRow(item, false)};

  @media screen and (max-width: 900px) {
    grid-column: ${({ item }) => getGridItemColumn(item, true)};
    grid-row: ${({ item }) => getGridItemRow(item, true)};
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 600px 600px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 450px;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 100vw;
  }
`;
