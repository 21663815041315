import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FullWidthImage, ValuesGrid, ValuesContainer } from "./values-elements";
import Border from "../border/border";
import City from "../../images/city.png";

const useStyles = makeStyles({
  border: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  valuesTitle: {
    fontSize: "1.0625rem",
    fontWeight: 600,
    margin: "0 0 12px 0",
  },
});

const Values = () => {
  const classes = useStyles();

  return (
    <>
      <FullWidthImage src={City}>
        <ValuesContainer>
          <div className={classes.border}>
            <Border />
          </div>
          <Typography variant="h2" component="h2">
            Our Values and Policies
          </Typography>
          <ValuesGrid>
            <Typography className={classes.valuesTitle}>Customers first</Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Evolve and adapt
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Build connections
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Be extraordinary
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Property protection
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Respect and trust
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Passion for success
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Open communication
            </Typography>
            <Typography component="p" className={classes.valuesTitle}>
              Work as one team
            </Typography>
          </ValuesGrid>
        </ValuesContainer>
      </FullWidthImage>
    </>
  );
};

export default Values;
