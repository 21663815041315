import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ContentContainer, Description, IconContainer } from "./information-icon-elements";

const useStyles = makeStyles({
  text: {
    padding: "16px 0 8px 0",
    margin: "auto",
  },
  description: {
    width: 40,
    margin: "auto",
  },
});
const InformationIcon = ({ icon, title, children }) => {
  const classes = useStyles();
  return (
    <>
      <ContentContainer>
        <IconContainer>{icon}</IconContainer>
        <Typography variant="h6" component="h6" className={classes.text}>
          {title}
        </Typography>
        <Description>
          <Typography variant="body1" component="p" color="textSecondary">
            {children}
          </Typography>
        </Description>
      </ContentContainer>
    </>
  );
};

export default InformationIcon;
