import styled from "styled-components";

export const FullWidthImage = styled.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 400px;
  color: white;
  margin-left: -256px;

  @media screen and (max-width: 1600px) {
    margin-left: -128px;
  }

  @media screen and (max-width: 1300px) and (max-width: 800px) {
    margin-left: -64px;
  }

  @media screen and (max-width: 900px) {
    height: auto;
  }

  @media screen and (max-width: 500px) {
    margin-left: -16px;
  }
`;

export const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 64px 64px;
  justify-content: center;
  margin: 32px 0;
  text-align: center;

  @media screen and (max-width: 900px) {
    grid-template-columns: 256px;
    grid-template-rows: none;
  }
`;

export const ValuesContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 0 256px;

  @media screen and (max-width: 1600px) {
    padding: 0 128px;
  }

  @media screen and (max-width: 1300px) and (max-width: 800px) {
    padding: 0 64px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 16px;
  }
`;
