import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Card, Container, Text } from "./gradient-card-elements";
import Gradient from "../../svgs/gradient.svg";

const GradientCard = () => (
  <Container>
    <Card>
      <Text>
        <Typography variant="h5">
          <Box textAlign="left">The shortest way to get anywhere is to have good company</Box>
        </Typography>
        <br />
        <Typography variant="h5">
          <Box fontStyle="italic">- H. Jackson Brown Jr.</Box>
        </Typography>
      </Text>
      <Gradient />
    </Card>
  </Container>
);

export default GradientCard;
